import { useContext } from 'react';
import { PageContext } from '@/components/layout/page/PageContext';
import {
	differenceInHours,
	format,
	formatDistanceToNow,
	formatDistanceToNowStrict,
	parseISO
} from 'date-fns';
import dateFnsDe from 'date-fns/locale/de';
import dateFnsFr from 'date-fns/locale/fr';
import dateFnsEs from 'date-fns/locale/es';
import dateFnsPt from 'date-fns/locale/pt';
import { string } from 'prop-types';

const locales = {
	EN: null,
	ES: dateFnsEs,
	FR: dateFnsFr,
	DE: dateFnsDe,
	PT: dateFnsPt
};

const THRESHOLD_HOURS = 24;
const DATE_FORMAT = 'LLLL d, y';
const DATE_FORMAT_STRICT = 'MMM dd, yyyy h:mm a';
const addPrefix = true;
const addSuffix = true;
const currentDate = Date.now();

/**
 * @function TimeElapsed
 * @description provides time elapsed from date prop to present
 * If the hours elapsed is greater than THRESHOLD_HOURS, this provides a formatted date
 * TimeElapsed is translated into supported languages for international users
 * currentDate is calculated when page is loaded from server
 * @param {String} props.date
 * @param {Boolean} props.strict
 * @returns {React.ReactElement}
 */
const TimeElapsed = ({ date, strict }) => {
	const { lang } = useContext(PageContext);
	const itemDate = parseISO(date);
	const hoursElapsed = differenceInHours(currentDate, itemDate);

	const dateFormatConfig = strict ? DATE_FORMAT_STRICT : DATE_FORMAT;
	const distanceFormatter = strict ? formatDistanceToNowStrict : formatDistanceToNow;
	const localeConfig = { locale: locales[lang] };
	const distanceConfig = {
		...localeConfig,
		addPrefix,
		addSuffix
	};
	const isExpired = hoursElapsed > THRESHOLD_HOURS;
	const formattedDate = isExpired
		? format(itemDate, dateFormatConfig, localeConfig)
		: distanceFormatter(parseISO(date), distanceConfig);
	return formattedDate;
};

TimeElapsed.propTypes = {
	date: string.isRequired
};

export default TimeElapsed;
